import * as types from "../actionTypes";
import { produce } from "immer";

const intialState = {
  currentUser: {},
  userChatId: "",
  userType: "individual",
};

export default function authReducer(state = { ...intialState }, action) {
  switch (action.type) {
    case types.SET_USER_DETAILS:
      return produce(state, (draft) => {
        draft.currentUser = action.currentUser;
      });
    case types.SET_CHAT_ID:
      return produce(state, (draft) => {
        draft.userChatId = action.chatId;
      });

    case types.SET_USER_TYPE:
      return produce(state, (draft) => {
        draft.userType = action.userType;
      });

    default:
      return state;
  }
}
