import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import "tailwindcss/tailwind.css"; // Ensure Tailwind CSS is installed
import { getAPIBaseUrl } from "../../utility/constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#3B364B",
    border: "none",
    color: "white ",
    opacity: "50%",
    "&:hover": {
      borderColor: "#3B364B",
    },
    minHeight: "44px",
    fontSize: 14,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white ",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "white",
    opacity: "50%",
    fontSize: 14,
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#3B364B",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#2C2738" : "#3B364B",
    color: "white",
    opacity: "50%",
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#2C2738",
    },
  }),
};

const noOfUsers = [
  { value: "Just 1", label: "Just me" },
  { value: "2-5", label: "2-5" },
  { value: "6-10", label: "6-10" },
  { value: "10-50", label: "10-50" },
  { value: "50 +", label: "More than 50" },
];

const dataVolumeOptions = [
  { value: "1-10GB", label: "1-10GB" },
  { value: "10-50GB", label: "10-50GB" },
  { value: "50GB+", label: "50GB+" },
];
const onboardingOptions = [
  {
    value: "Self-guided Tutorials",
    label: "Self-guided Tutorials",
  },
  { value: "Video Sessions", label: "Video Sessions" },
  { value: "One-on-One Training", label: "One-on-One Training" },
  { value: "Documentation Only", label: "Documentation Only" },
];

const growthOptions = [
  { value: "None", label: "None" },
  { value: "10-20%", label: "10-20%" },
  { value: "20-50%", label: "20-50%" },
  { value: "50%+", label: "50%+" },
];

const EnterpriseContact = () => {
  const { currentUser } = useSelector((state) => state.auth);
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    numberOfUsers: "",
    customFrequency: "",
    primaryUseCases: [],
    dataVolume: "",
    prioritySupport: "No",
    supportExplanation: "",
    onboarding: null,
    expectedGrowth: null,
  });
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const questions = [
    {
      render: (
        <div className="space-y-2">
          <p className="text-base text-white opacity-60 mb-4 pt-8">
            How many users will access the platform?
          </p>
          <Select
            styles={customSelectStyles}
            options={noOfUsers}
            onChange={(option) =>
              setFormData({ ...formData, numberOfUsers: option.value })
            }
            placeholder="No of users"
            value={noOfUsers.filter(
              (el) => el.value === formData.numberOfUsers
            )}
          />
        </div>
      ),
    },
    {
      render: (
        <div className="space-y-2">
          <p className="text-base text-white opacity-60 mb-4 pt-8">
            How often will your team use the platform?
          </p>
          {["Daily", "Weekly", "Monthly"].map((option) => (
            <div key={option} className="flex items-center">
              <input
                type="radio"
                name="frequency"
                value={option}
                checked={formData.customFrequency === option}
                onChange={(e) =>
                  setFormData({ ...formData, customFrequency: e.target.value })
                }
                className="h-4 w-4 text-indigo-600 dark:text-indigo-400 focus:ring-indigo-500 dark:focus:ring-indigo-400 border-gray-300 dark:border-gray-600 rounded"
                required
              />
              <label className="ml-2 text-sm text-white opacity-60">
                {option}
              </label>
            </div>
          ))}
        </div>
      ),
    },
    {
      render: (
        <div className="space-y-2">
          <p className="text-base text-white opacity-60 mb-4 pt-8">
            What are your main use cases for the platform?
          </p>
          {[
            "Data Analysis",
            "Collaboration",
            "Content Creation",
            "Customer Support",
            "Others",
          ].map((useCase) => (
            <div key={useCase} className="flex items-center">
              <input
                type="checkbox"
                name="primaryUseCases"
                value={useCase}
                checked={formData.primaryUseCases.includes(useCase)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFormData({
                      ...formData,
                      primaryUseCases: [...formData.primaryUseCases, useCase],
                    });
                  } else {
                    setFormData({
                      ...formData,
                      primaryUseCases: formData.primaryUseCases.filter(
                        (caseItem) => caseItem !== useCase
                      ),
                    });
                  }
                }}
                className="h-4 w-4 text-indigo-600 dark:text-indigo-400 focus:ring-indigo-500 dark:focus:ring-indigo-400 border-gray-300 dark:border-gray-600 rounded"
              />
              <label className="ml-2 text-sm text-white opacity-60">
                {useCase}
              </label>
            </div>
          ))}
        </div>
      ),
    },
    {
      render: (
        <div className="space-y-2">
          <p className="text-base text-white opacity-60 mb-4 pt-8">
            What is the estimated volume of data your team will generate
            monthly?
          </p>
          <Select
            styles={customSelectStyles}
            options={dataVolumeOptions}
            onChange={(option) =>
              setFormData({ ...formData, dataVolume: option.value })
            }
            value={dataVolumeOptions.filter(
              (el) => el.value === formData.dataVolume
            )}
            placeholder="Select data volume"
          />
        </div>
      ),
    },
    {
      render: (
        <div className="space-y-2">
          <p className="text-base text-white opacity-60 mb-4 pt-8">
            Do you need priority support?
          </p>
          <div className="flex items-center">
            <input
              type="radio"
              name="prioritySupport"
              value="Yes"
              checked={formData.prioritySupport === "Yes"}
              onChange={(e) =>
                setFormData({ ...formData, prioritySupport: e.target.value })
              }
              className="text-purple-600 bg-gray-700 border-gray-600 focus:ring-purple-500"
              required
            />
            <label className="ml-2 text-sm text-white opacity-60">Yes</label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              name="prioritySupport"
              value="No"
              checked={formData.prioritySupport === "No"}
              onChange={(e) =>
                setFormData({ ...formData, prioritySupport: e.target.value })
              }
              className="text-purple-600 bg-gray-700 border-gray-600 focus:ring-purple-500"
            />
            <label className="ml-2 text-sm text-white opacity-60">No</label>
          </div>
          {formData.prioritySupport === "Yes" && (
            <textarea
              name="supportExplanation"
              value={formData.supportExplanation}
              onChange={(e) =>
                setFormData({ ...formData, supportExplanation: e.target.value })
              }
              className="w-full p-3 rounded bg-black-200 text-sm text-white opacity-70 border border-gray-600 focus:outline-none"
              placeholder="Please explain your support needs"
            />
          )}
        </div>
      ),
    },
    {
      render: (
        <div className="space-y-2">
          <p className="text-base text-white opacity-60 mb-4 pt-8">
            What kind of onboarding support do you prefer
          </p>
          <Select
            styles={customSelectStyles}
            options={onboardingOptions}
            onChange={(option) =>
              setFormData({ ...formData, onboarding: option.value })
            }
            value={onboardingOptions.filter(
              (el) => el.value === formData.onboarding
            )}
            placeholder="Select onboarding preference"
          />
        </div>
      ),
    },
    {
      render: (
        <div className="space-y-2">
          <p className="text-base text-white opacity-60 mb-4 pt-8">
            What is the growth you're expecting
          </p>
          <Select
            styles={customSelectStyles}
            options={growthOptions}
            onChange={(option) =>
              setFormData({ ...formData, expectedGrowth: option.value })
            }
            value={growthOptions.filter(
              (el) => el.value === formData.expectedGrowth
            )}
            placeholder="Select expected growth"
          />
        </div>
      ),
    },
  ];

  const handleNext = () => {
    if (currentStep < questions.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      ...formData,
      ...currentUser,
    };

    try {
      const response = await axios.post(`${getAPIBaseUrl()}/send-pdf`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.log("error>>", error);
    } finally {
      setSuccessMessage(
        "Thank You ! Your form has been submitted successfully! "
      );

      setTimeout(() => {
        navigate("/login");
      }, [2000]);
    }
  };

  return (
    <div className="min-h-screen bg-black-400 xl:bg-black-100 flex flex-col justify-center items-center pt-6">
      {" "}
      <div className="w-full max-w-2xl p-8 bg-black-400 rounded-lg shadow-md">
        {successMessage ? (
          <>
            <div className="text-white text-xl mb-6 text-center">
              {successMessage}
            </div>
            <p className="text-white text-sm mb-4 text-center">
              Thank you for taking the time to fill out our form. We appreciate
              your feedback and will get back to you shortly.
            </p>
            <a href="/login" className="text-white text-sm underline">
              Back to Login
            </a>
          </>
        ) : (
          <>
            <h1 className="text-3xl font-bold text-white mb-4">
              Enterprise Contact Form
            </h1>
            <p className="text-sm   text-white opacity-70 mb-8">
              Please answer the following questions to help us better understand
              your needs.
            </p>

            <>
              <div className="relative w-full mb-4">
                <div className="absolute top-0 left-0 text-sm text-white opacity-50 mt-3 ">
                  Step {currentStep + 1} of {questions.length}
                </div>
                <div className="h-1 w-full bg-gray-300 dark:bg-gray-700 rounded-full mt-4">
                  <div
                    className="h-1 bg-purple-600 rounded-full"
                    style={{
                      width: `${((currentStep + 1) / questions.length) * 100}%`,
                    }}
                  ></div>
                </div>
              </div>
                <div>
                  <label className="block text-lg font-medium text-gray-800 dark:text-gray-200 mb-2">
                    {questions[currentStep].label}
                  </label>
                  <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
                    {questions[currentStep].description}
                  </p>
                  {questions[currentStep].render}
                </div>
                <div className="flex justify-between mt-8">
                  {currentStep > 0 && (
                    <button
                      type="button"
                      onClick={handlePrevious}
                      className="py-2 px-4 bg-gray-600 text-white rounded hover:bg-gray-700 transition"
                    >
                      Previous
                    </button>
                  )}
                  {currentStep < questions.length - 1 ? (
                    <button
                      type="button"
                      onClick={handleNext}
                      className="py-2 px-4 bg-gradient-to-r from-purple-600 to-indigo-600 text-white rounded hover:from-purple-700 hover:to-indigo-700 transition"
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="py-2 px-4 bg-gradient-to-r from-purple-600 to-indigo-600 text-white rounded hover:from-purple-700 hover:to-indigo-700 transition"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  )}
                </div>
            </>
          </>
        )}
      </div>
    </div>
  );
};

export default EnterpriseContact;
