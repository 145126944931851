import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAPIBaseUrl } from "../../utility/constants";
import backgroundImage from "../../assets/background.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { individualPlans, enterprisePlans } from "../../utils";

const stripePromise = loadStripe(
  "pk_live_51Q0ojDHG7saj5CINiX3EaCv8ORQTIb9nyOaQvmQ3R0vs7LkuuQtqfH13vzlFsim7Gk0yAiJMbb4CK8GtHDPSlJ0300fE1xnbYY"
);

const Billing = () => {
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("starter");
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const userType = queryParams.get("user-type") || "individual";
  const plans = userType === "enterprise" ? enterprisePlans : individualPlans;

  const { currentUser } = useSelector((state) => state.auth);
  console.log("currentUser>>",currentUser)

  const handleCheckout = async () => {
    setLoading(true);

      let requestData = {
        email: currentUser.email,
        plan: selectedPlan,
        user_type : userType === "enterprise" ? "enterprise" : "individual"

      }

      if(userType === "enterprise"){
          requestData = {
            ...requestData,
            enterprise_id : currentUser?.enterprise_id
          }
      }


    try {
      const { data } = await axios.post(
        `${getAPIBaseUrl()}/create-checkout-session`,
        requestData
      );

      const stripe = await stripePromise;
      const result = await stripe.redirectToCheckout({
        sessionId: data.id,
      });

      if (result.error) {
        console.error(result.error.message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to initiate checkout");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="min-h-screen bg-black-100 text-white flex flex-col justify-center items-center p-6"
      style={{
        background: `url(${backgroundImage})`,
        backgroundSize: "contain",
      }}
    >
      <h1 className="text-4xl font-semibold mb-8">Subscribe to GG AI</h1>
      <h2 className="text-2xl font-medium mb-4">Choose Your Plan</h2>

      <div className="w-full flex flex-col justify-center lg:flex-row w-full lg:space-x-8 space-y-6 lg:space-y-0">
        {plans.map((plan) => (
          <PlanCard
            value={plan.value}
            title={plan.title}
            price={plan.price}
            trialInfo={plan.trialInfo}
            features={plan.features}
            selected={selectedPlan === plan.value}
            onSelect={() => setSelectedPlan(plan.value)}
            description={plan?.description}
          />
        ))}
      </div>

      {selectedPlan !== "contact" && (
        <button
          onClick={handleCheckout}
          disabled={loading}
          className="mt-10 bg-purple-600  text-white font-semibold py-3 px-10 rounded-lg h-12 transition duration-200"
        >
          {loading ? "Processing..." : "Subscribe"}
        </button>
      )}
    </div>
  );
};

const PlanCard = ({
  title,
  price,
  trialInfo,
  features,
  selected,
  onSelect,
  isCurrent,
  value,
  description,
}) => {
  const navigate = useNavigate();

  console.log("description", description + "..." + value);
  return (
    <div
      className={`flex flex-col p-8 w-full lg:w-[20%] shadow-lg rounded-lg cursor-pointer transition transform ${
        selected
          ? "border-4 border-white scale-105 bg-black-600"
          : "border border-white bg-black-600"
      } hover:border-white`}
      onClick={onSelect}
    >
      <h3 className="text-2xl font-bold mb-2">{title}</h3>
      {value !== "contact" && (
        <>
          <p className="text-3xl font-bold my-4">{price}</p>
          <p className="text-sm text-purple-300 mb-6">{trialInfo}</p>
        </>
      )}
      {value === "contact" ? (
        <>
          <div className="text-gray-400 mt-5">{description}</div>
          <button
            onClick={() => {
              navigate("/contact-us");
            }}
            className="mt-10 bg-white  text-black-100 font-medium py-3 px-10 rounded-lg h-12 transition duration-200"
          >
            Contact Us
          </button>
        </>
      ) : (
        <div className="text-gray-400 text-base mb-6">
          {features &&
            features.length > 0 &&
            features.map((feature, index) => (
              <div key={index} className="flex items-center mb-2">
                <span className="mr-2 text-green-400">✓</span> {feature}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default Billing;
