import moment from "moment/moment";
import React from "react";
import { useMediaQuery } from "react-responsive";

const UserBillingInfo = ({ billingInfo }) => {
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div className="flex-grow p-4 md:p-8">
      <h2 className="text-2xl md:text-3xl font-bold mb-4">Billing</h2>
      <p className="text-gray-400 mb-6">
        Manage your billing information and invoices
      </p>

      <div className="bg-black-300 p-4 md:p-6 rounded-lg mb-4 md:mb-6 shadow-lg border border-gray-700">
        <h3 className="text-lg md:text-xl font-semibold mb-4">Payment Method</h3>
        <p className="text-sm md:text-base">
          <span className="font-medium">Card Number: </span>
          {billingInfo?.payment_method?.card?.last4
            ? `**** **** **** ${billingInfo.payment_method.card.last4}`
            : "N/A"}
        </p>
      </div>

      <div className="bg-black-300 p-4 md:p-6 rounded-lg mb-4 md:mb-6 shadow-lg border border-gray-700">
        <h3 className="text-lg md:text-xl font-semibold mb-4">Plan</h3>
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
          <div>
            <p className="uppercase text-sm md:text-base">
              {billingInfo?.payment_info?.plan || "N/A"}
            </p>
            <p className="text-xs md:text-sm text-gray-400 mt-1">
              {billingInfo?.payment_info?.is_trial
                ? `Trial ends at ${
                    billingInfo?.payment_info?.trial_end_date
                      ? moment(billingInfo?.payment_info?.trial_end_date).format(
                          "MMM DD YYYY"
                        )
                      : "NA"
                  }`
                : ""}
            </p>
          </div>
        </div>
      </div>

      {/* Invoice History */}
      <div className="bg-black-300 p-4 md:p-6 rounded-lg mb-4 md:mb-6 shadow-lg border border-gray-700">
        <h3 className="text-lg md:text-xl font-semibold mb-2">Invoice History</h3>
        <div className="p-4 bg-black-300 rounded space-y-4">
          {billingInfo.invoices && billingInfo.invoices.length > 0 ? (
            billingInfo.invoices.map((invoice, index) => (
              <div
                className="flex flex-row justify-between items-center border-b border-gray-600 pb-2"
                key={index}
              >
                <span className="text-sm md:text-base mt-1">
                  {new Date(invoice.date * 1000).toLocaleDateString()}
                </span>
                <span className="capitalize bg-violet-100 py-1 px-2 md:py-2 md:px-4 rounded-lg text-sm md:text-base mt-2 md:mt-0">
                  {invoice.status.charAt(0).toUpperCase() +
                    invoice.status.slice(1)}
                </span>
                <span className="text-sm md:text-base mt-2 md:mt-0">
                  US${(invoice.amount / 100).toFixed(2)}
                </span>
                <a
                  href={invoice.pdf_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-purple-50 text-sm md:text-base mt-2 md:mt-0"
                >
                  View Invoice
                </a>
                <span className="text-sm md:text-base mt-2 md:mt-0">
                 GG Subscription
                </span>
              </div>
            ))
          ) : (
            <p className="text-gray-400">No invoices found.</p>
          )}
        </div>
      </div>

      {/* Billing Details */}
      <div className="bg-black-300 p-4 md:p-6 rounded-lg mb-4 md:mb-6 shadow-lg border border-gray-700">
        <h3 className="text-lg md:text-xl font-semibold mb-4">Billing Details</h3>
        <p className="text-sm md:text-base mb-2">
          <span className="font-semibold">Name: </span>
          {billingInfo?.payment_method?.billing_details?.name || "N/A"}
        </p>
        <p className="text-sm md:text-base mb-2">
          <span className="font-semibold">Billing Email: </span>
          {billingInfo?.payment_method?.billing_details?.email || "N/A"}
        </p>
        <p className="text-sm md:text-base mb-2">
          <span className="font-semibold">Address: </span>
          {billingInfo?.payment_method?.billing_details?.address?.line1 || "N/A"}
        </p>
        <p className="text-sm md:text-base mb-2">
          <span className="font-semibold">City: </span>
          {billingInfo?.billing_address?.city || "N/A"}
        </p>
        <p className="text-sm md:text-base mb-2">
          <span className="font-semibold">State: </span>
          {billingInfo?.billing_address?.state || "N/A"}
        </p>
        <p className="text-sm md:text-base mb-2">
          <span className="font-semibold">Postal Code: </span>
          {billingInfo?.billing_address?.postal_code || "N/A"}
        </p>
      </div>
    </div>
  );
};

export default UserBillingInfo;
