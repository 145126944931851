import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { getAPIBaseUrl } from "../../utility/constants";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [enterpriseId, setEnterpriseId] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setEmail(params.get("email"));
    setToken(params.get("token"));
    setEnterpriseId(params.get("enterprise_id"));
  }, [location]);

  const handleResetPassword = async () => {
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    if (!password) {
      toast.error("Password is required");
      return;
    }

    setLoading(true);
    try {
      const data = {
        email,
        token,
        new_password: password,
        enterprise_id: enterpriseId,
      };
      const response = await axios.post(
        `${getAPIBaseUrl()}/reset-password`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Password reset successfully!");
        navigate("/login");
      }
    } catch (error) {
      toast.error(error.response?.data?.error || "Error resetting password");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-black text-white">
      <div className="w-full max-w-md p-8 bg-gray-800 rounded-lg shadow-lg">
        <h1 className="text-3xl font-semibold text-center mb-6">
          Reset Password
        </h1>

        <div className="mb-4">
          <label className="block text-sm font-medium">New Password</label>
          <input
            type="password"
            placeholder="Enter new password"
            className="w-full px-4 py-2 mt-2 rounded-md bg-gray-700 text-white border border-gray-600 focus:outline-none"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium">Confirm Password</label>
          <input
            type="password"
            placeholder="Confirm new password"
            className="w-full px-4 py-2 mt-2 rounded-md bg-gray-700 text-white border border-gray-600 focus:outline-none"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>

        <button
          onClick={handleResetPassword}
          className={`w-full py-1 h-10 mt-4 bg-gradient-to-r from-purple-600 to-indigo-600 rounded-md text-white font-semibold ${
            loading || !password || !confirmPassword || password !== confirmPassword ? "opacity-50" : ""
          }`}
          disabled={loading || !password || !confirmPassword || password !== confirmPassword}
        >
          {loading ? <Loader /> : "Reset Password"}
        </button>
      </div>
    </div>
  );
};

export default ResetPassword;
