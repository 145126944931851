import * as actionTypes from "../actionTypes";

export const setUserDetails = (currentUser) => {
  return { type: actionTypes.SET_USER_DETAILS, currentUser };
};

export const setChatId = (chatId) => {
  return { type: actionTypes.SET_CHAT_ID, chatId };
};


export const setUserActionType = (userType) => {
return { type: actionTypes.SET_USER_TYPE, userType };
};




