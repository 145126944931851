import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaBackward, FaBars, FaTimes } from "react-icons/fa";
import UserBillingInfo from "./UserBillingInfo";
import axios from "axios";
import { getAPIBaseUrl } from "../../utility/constants";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import EnterpriseDashboard from "../EnterpriseDashboard";
import { toast } from "react-toastify";
import { googleLogout } from "@react-oauth/google";
import { setUserDetails } from "../../redux/actions/authActions";
import { IoCloseCircle } from "react-icons/io5";
import { CustomModal } from "../../components/CustomModal";
import PhoneInput from "react-phone-number-input";
import Loader from "../../components/Loader";

const UserSettings = () => {
  const [selectedTab, setSelectedTab] = useState("builderProfile");
  const [billingInfo, setBillingInfo] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const [isMfaModalOpen, setMfaModalOpen] = useState(false);
  const [mfaMethod, setMFaMethod] = useState("");
  const [mfaScreen, setMfaScreen] = useState("select-method");
  const [qrCode, setQrCode] = useState("");
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showDisableMfaModal, setDisableMfaModal] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const navigate = useNavigate();
  const storeDispatch = useDispatch();

  const { currentUser, userType } = useSelector((state) => state.auth);
  const theme = "dark";

  const logoutUser = () => {
    storeDispatch(setUserDetails({}));
    googleLogout();
    navigate("/login");
  };

  const sendOtpHandler = async () => {
    setOtpLoading(true)
    const data = {
      email: currentUser?.email,
      phoneNumber: phoneNumber,
    };
    try {
      const response = await axios.post(`${getAPIBaseUrl()}/send-otp`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setSuccessMessage("OTP has been sent successfully!");
    } catch (error) {
      toast.error("Something went wrong ! Please try again !");
    }
    finally{
      setOtpLoading(false)
    }
  };
  const disableMfaHandler = async () => {
    const data = {
      email: currentUser?.email,
      otp: otp,
    };
    try {
      const response = await axios.post(
        `${getAPIBaseUrl()}/disable-mfa`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("MFA is disabled successfully !");
      getUserInfo();
      setDisableMfaModal(false);
    } catch (error) {
      toast.error("Unable to disable MFA! Try agiain");
    }
  };

  const verifyPhoneOtpHandler = async () => {
    setLoading(true);
    const data = {
      email: currentUser?.email,
      otp: otp,
    };
    try {
      const response = await axios.post(
        `${getAPIBaseUrl()}/verify-phone-otp`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setMfaModalOpen(false);
      setMFaMethod("");
      setMfaScreen("select-method");
      setOtp("");
      toast.success("MFA is enabled successfully !");
      getUserInfo();
    } catch (error) {
      toast.error("Invalid code !");
    } finally {
      setLoading(false);
    }
  };

  const verifyOtpHandler = async () => {
    setLoading(true);

    const data = {
      email: currentUser?.email,
      otp: otp,
    };
    try {
      const response = await axios.post(
        `${getAPIBaseUrl()}/verify-authenticator-otp`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setMfaModalOpen(false);
      setQrCode("");
      setMFaMethod("");
      setMfaScreen("select-method");
      setOtp("");
      toast.success("MFA is enabled successfully !");
      getUserInfo();
    } catch (error) {
      toast.error("Invalid code !");
    } finally {
      setLoading(false);
    }
  };
  const enableMfaHandler = async () => {
    if (mfaMethod === "authenticator") {
      const data = {
        email: currentUser?.email,
      };
      try {
        const response = await axios.post(
          `${getAPIBaseUrl()}/generate-qr`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setMfaScreen("verify-otp");
        setQrCode(response?.data?.qr_code);

        console.log("response>>>", response);
      } catch (error) {
        setQrCode("");

        toast.error("Unable top process! Please try again!");
      }
    } else {
      setMfaScreen("verify-otp");
    }
  };

  console.log("user>>", userInfo);

  const deleteUserHandler = async () => {
    try {
      const response = await axios.delete(
        `${getAPIBaseUrl()}/delete-user?email=${currentUser.email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("User deleted successfully!");
      setShowModal(false);

      logoutUser();
    } catch (error) {
      toast.error("Failed to delete user.");
    }
  };

  const deleteAllChatHandler = async () => {
    try {
      const response = await axios.delete(
        `${getAPIBaseUrl()}/delete-chat-history?email=${currentUser.email}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Chats  deleted successfully!");
      setShowChatModal(false);
    } catch (error) {
      toast.error("Failed to delete chats.");
    }
  };

  const getUserInfo = async () => {
    setLoading(true);
    try {
      const email = currentUser && currentUser.email ? currentUser.email : "";
      const user_type = userType;
      const enterprise_id =
        currentUser && currentUser.enterprise_id
          ? currentUser.enterprise_id
          : "";

      if (email) {
        const response = await axios.get(
          `${getAPIBaseUrl()}/get-user-info?user_email=${email}&user_type=${user_type}&enterprise_id=${enterprise_id}`
        );

        if (response && response.data && response.data.user_info) {
          setUserInfo(response.data.user_info);
        }
      } else {
        setUserInfo({});
      }
    } catch (error) {
      console.error("Error fetching billing info:", error);
      setUserInfo({});
    } finally {
      setLoading(false);
    }
  };

  const getUserBillingInfo = async () => {
    setLoading(true);
    try {
      const email =
        userType === "individual"
          ? currentUser.email
          : currentUser && currentUser.user && currentUser.user.email
          ? currentUser.user.email
          : "";
      const user_type = userType;
      const enterprise_id =
        userType === "enterprise"
          ? currentUser &&
            currentUser.enterprise &&
            currentUser.enterprise.enterprise_id
            ? currentUser.enterprise.enterprise_id
            : ""
          : "";

      if (email) {
        const response = await axios.get(
          `${getAPIBaseUrl()}/get-billing-info?user_email=${email}&user_type=${user_type}&enterprise_id=${enterprise_id}`
        );

        if (response && response.data) {
          setBillingInfo(response.data);
        }
      } else {
        setBillingInfo({});
      }
    } catch (error) {
      console.error("Error fetching billing info:", error);
      setBillingInfo({});
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserInfo();
    getUserBillingInfo();
  }, []);

  useEffect(() => {
    if (
      currentUser &&
      Object.keys(currentUser).length > 0 &&
      currentUser.phone_number
    ) {
      setPhoneNumber(currentUser?.phone_number);
    }
  }, [currentUser]);

  const progressPercentage = Math.min(
    (userInfo.tokens_used / userInfo.tokens_limit) * 100,
    100
  );

  console.log("mfaMethod", mfaMethod);
  return (
    <div
      className={`min-h-screen flex ${
        theme === "dark" ? "bg-black-600 text-white" : "bg-white text-gray-900"
      }`}
    >
      {isMobile ? (
        <>
          <div
            className={`fixed top-0 left-0 z-50 w-full p-4 flex justify-between items-center bg-black-700 ${
              isDrawerOpen ? "rounded-t-lg" : ""
            }`}
          >
            <div
              className="flex flex-row gap-2 cursor-pointer"
              onClick={() => {
                navigate("/");
              }}
            >
              <IoMdArrowRoundBack size={20} className="mt-1" />
              <div className="mt-1 text-sm">Back</div>
            </div>
            <div>
              {isDrawerOpen ? (
                <FaTimes
                  size={24}
                  className="cursor-pointer"
                  onClick={() => setDrawerOpen(false)}
                />
              ) : (
                <FaBars
                  size={24}
                  className="cursor-pointer"
                  onClick={() => setDrawerOpen(true)}
                />
              )}
            </div>
          </div>
          {isDrawerOpen && (
            <aside className="fixed top-12 left-0 z-40 w-64 h-full bg-black-700 p-4 shadow-lg">
              <nav>
                <ul>
                  <li
                    className={`mb-4 py-3 rounded-lg cursor-pointer ${
                      selectedTab === "builderProfile"
                        ? "bg-black-300 px-3"
                        : "pl-3"
                    }`}
                    onClick={() => {
                      setSelectedTab("builderProfile");
                      setDrawerOpen(false);
                    }}
                  >
                    Builder Profile
                  </li>
                  <li
                    className={`mb-4 py-3 rounded-lg cursor-pointer ${
                      selectedTab === "billing" ? "bg-black-300 px-3" : "pl-3"
                    }`}
                    onClick={() => {
                      setSelectedTab("billing");
                      setDrawerOpen(false);
                    }}
                  >
                    Billing
                  </li>
                </ul>
              </nav>
            </aside>
          )}
        </>
      ) : (
        <aside className="w-64 py-4 flex flex-col bg-black-700 rounded-r-lg shadow-lg">
          <div className="flex flex-row justify-between border-b border-white border-opacity-30 mb-6 px-2 pb-4">
            <div
              className="flex flex-row gap-2 cursor-pointer"
              onClick={() => {
                navigate("/");
              }}
            >
              <IoMdArrowRoundBack size={20} className="mt-1" />
              <div className="mt-1 text-sm">Back</div>
            </div>
          </div>
          <nav className="px-3">
            <ul>
              <li
                className={`mb-4 py-3 rounded-lg cursor-pointer ${
                  selectedTab === "builderProfile"
                    ? "bg-black-300 px-3"
                    : "pl-3"
                }`}
                onClick={() => {
                  setSelectedTab("builderProfile");
                }}
              >
                Builder Profile
              </li>

              <li
                className={`mb-4 py-3 rounded-lg cursor-pointer ${
                  selectedTab === "billing" ? "bg-black-300 px-3" : "pl-3"
                }`}
                onClick={() => {
                  setSelectedTab("billing");
                }}
              >
                Billing
              </li>
            </ul>
          </nav>
        </aside>
      )}

      <div className="flex-1  mt-12 xl:mt-2">
        {selectedTab === "builderProfile" && (
          <div>
            {userType === "individual" ? (
              <div className="p-6 max-w-3xl  rounded-lg  mt-4">
                <div className="space-y-4">
                  <div className="flex justify-between items-center">
                    <label className="text-base font-semibold text-gray-400">
                      Name:
                    </label>
                    <p className="text-lg font-medium text-white">
                      {userInfo?.name || "N/A"}
                    </p>
                  </div>
                  <div className="flex justify-between items-center">
                    <label className="text-base font-semibold text-gray-400">
                      Email:
                    </label>
                    <p
                      className="text-lg font-medium text-white w-[50%] text-right"
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        WebkitLineClamp: 1,
                        wordBreak: "break-all",
                      }}
                    >
                      {userInfo?.email || "N/A"}
                    </p>
                  </div>
                  <div className="flex justify-between items-center">
                    <label className="text-base font-semibold text-gray-400">
                      Phone Number:
                    </label>
                    <p className="text-lg font-medium text-white">
                      {userInfo?.phone_number || "N/A"}
                    </p>
                  </div>
                  <div className="flex justify-between items-center">
                    <label className="text-base font-semibold text-gray-400">
                      Verified:
                    </label>
                    <p className="text-lg font-medium text-white">
                      {userInfo?.is_verified ? "Yes" : "No"}
                    </p>
                  </div>
                </div>

                <div className="py-4  border-opacity-20 flex flex-row justify-between mt-2">
                  <div className="text-base font-semibold text-gray-400">
                    Enable MFA
                  </div>
                  <label class="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      class="sr-only peer"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setMfaModalOpen(e.target.checked);
                        } else {
                          setDisableMfaModal(true);
                        }
                      }}
                      checked={userInfo?.mfa_enabled}
                    />
                    <div class="relative w-11 h-6 bg-gray-500 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-300"></div>
                  </label>
                </div>
                <div className="py-4  border-opacity-20 flex flex-row justify-between">
                  <div className="text-base font-semibold text-gray-400">
                    Delete All Chats
                  </div>
                  <button
                    className="bg-red-100 font-sans text-xs outline-none borer border-red-100 flex flex-row items-center justify-center 
            text-white  rounded-full py-2 px-3 
            "
                    onClick={() => {
                      setShowChatModal(true);
                    }}
                  >
                    Delete all
                  </button>
                </div>
                <div className="py-4  flex flex-row justify-between">
                  <div className="text-base font-semibold text-gray-400">
                    Delete Account
                  </div>
                  <button
                    className="bg-red-100 font-sans text-xs outline-none borer border-red-100 flex flex-row items-center justify-center 
            text-white  rounded-full py-2 px-5 
            "
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    Delete
                  </button>
                </div>
                <div className="mt-6">
                  <label className="text-base font-semibold text-gray-400 mb-2 block">
                    Token Usage
                  </label>
                  <div className="relative w-full h-6 bg-gray-700 rounded-full overflow-hidden">
                    <div
                      className="absolute top-0 left-0 h-full bg-violet-100 transition-all"
                      style={{
                        width: `${
                          (Number(userInfo?.tokens_used || 0) /
                            Number(userInfo?.tokens_limit || 1)) *
                          100
                        }%`,
                      }}
                    ></div>
                  </div>
                  <div className="text-sm text-gray-400 mt-2 text-center">
                    {userInfo?.tokens_used} / {userInfo?.tokens_limit} Tokens
                    Used (
                    {(
                      (Number(userInfo?.tokens_used || 0) /
                        Number(userInfo?.tokens_limit || 1)) *
                      100
                    ).toFixed(2)}
                    %)
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <EnterpriseDashboard />
              </div>
            )}
          </div>
        )}
        {selectedTab === "billing" && (
          <UserBillingInfo billingInfo={billingInfo} />
        )}
      </div>
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-gray-800 p-6 rounded-lg w-full max-w-sm text-center shadow-lg mx-4 sm:mx-0">
            <h2 className="text-xl font-semibold text-white mb-4">
              Confirm Delete
            </h2>
            <p className="text-gray-300 mb-6">
              Are you sure you want to delete the account
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={() => {
                  deleteUserHandler();
                }}
                className="bg-red-600 hover:bg-red-700 text-white rounded-md font-semibold px-4 py-2"
              >
                Yes, Delete
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="bg-gray-600 hover:bg-gray-700 text-white rounded-md font-semibold px-4 py-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <CustomModal
        isOpen={isMfaModalOpen}
        handleClose={() => {
          setMfaModalOpen(false);
        }}
      >
        <div className="bg-gray-800">
          <div className="bg-gray-800 p-6 rounded-lg w-fulltext-center shadow-lg mx-4 sm:mx-0">
            <div className="flex flex-row justify-between  mb-4">
              {mfaScreen === "select-method" ? (
                <h2 className="text-xl font-semibold text-white ">
                  Do you want to enable Multi Factor Authentication
                </h2>
              ) : (
                <div
                  className="flex flex-row gap-2 cursor-pointer"
                  onClick={() => {
                    setMfaScreen("select-method");
                  }}
                >
                  <IoMdArrowRoundBack size={20} className="mt-1" />
                  <div className="mt-1 text-sm">Back</div>
                </div>
              )}
              <div
                className="text-xl font-semibold text-white  cursor-pointer mt-1"
                onClick={() => {
                  setMfaModalOpen(false);
                  setMFaMethod("");
                }}
              >
                <IoCloseCircle />
              </div>
            </div>
            {mfaScreen === "select-method" ? (
              <>
                <p className="text-white opacity-70 mt-10 mb-2">
                  Please select any one option
                </p>
                <div className="flex flex-row space-x-12">
                  <div className="flex flex-row gap-3 mr-1">
                    <input
                      type="radio"
                      onChange={() => {
                        setMFaMethod("authenticator");
                      }}
                      checked={mfaMethod === "authenticator"}
                      className="w-4 h-4 cursor-pointer mt-1"
                    />
                    <label> Authenticator App</label>
                  </div>
                  <div className="flex flex-row gap-2 mr-1">
                    <input
                      type="radio"
                      onChange={() => {
                        setMFaMethod("phone");
                      }}
                      checked={mfaMethod === "phone"}
                      className="w-4 h-4 cursor-pointer mt-1"
                    />
                    <label> Phone </label>
                  </div>
                </div>
                <button
                  className={`w-full mt-12 flex justify-center items-center h-12 py-3 bg-gradient-to-r from-purple-600 to-indigo-600 text-white font-semibold rounded-full shadow-lg hover:from-purple-700 hover:to-indigo-700 transition
${!mfaMethod ? "opacity-70 cursor-not-allowed" : "cursor-pointer"}`}
                  disabled={!mfaMethod}
                  onClick={enableMfaHandler}
                >
                  Enable MFA
                </button>
              </>
            ) : mfaMethod === "authenticator" ? (
              <>
                {qrCode && (
                  <div>
                    <div className="flex flex-row justify-center mt-4 mb-2">
                      <img
                        src={`data:image/png;base64,${qrCode}`}
                        alt="QR Code"
                        className="w-[160px] h-[160px]"
                      />
                    </div>
                    <p className="text-center">
                      Scan this QR code with your authenticator app.
                    </p>
                  </div>
                )}
                <div className="mt-8">
                  <input
                    type="number"
                    className=" my-3 appearance-none bg-purple-200 relative block w-full p-3  placeholder-gray-500 text-white rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter the code"
                    onChange={(e) => {
                      setOtp(e.target.value);
                    }}
                    value={otp}
                  />
                  <button
                    className={`w-full mt-3 flex justify-center items-center h-12 py-3 bg-gradient-to-r from-purple-600 to-indigo-600 text-white font-semibold rounded-full shadow-lg hover:from-purple-700 hover:to-indigo-700 transition
                    ${
                      !mfaMethod
                        ? "opacity-70 cursor-not-allowed"
                        : "cursor-pointer"
                    }`}
                    onClick={verifyOtpHandler}
                  >
                    {loading ? <Loader /> : "Verify"}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="text-white opacity-70 text-sm">
                  Please enter your phone number
                </div>
                <div className="ph-input-container w-full flex flex-row gap-x-2">
                  <PhoneInput
                    placeholder="Phone number"
                    value={phoneNumber}
                    onChange={(value) => {
                      setPhoneNumber(value);
                    }}
                  />
                  <button
                    className={`w-full  flex justify-center items-center h-10 py-3 bg-gradient-to-r from-purple-600 to-indigo-600 text-white font-semibold rounded-lg mt-1.5 ml-1 shadow-lg hover:from-purple-700 hover:to-indigo-700 transition
                    ${
                      !mfaMethod
                        ? "opacity-70 cursor-not-allowed"
                        : "cursor-pointer"
                    }`}
                    onClick={sendOtpHandler}
                  >
                    {
                        otpLoading ? <Loader /> : "Send OTP"
                    }
                  </button>
                </div>
                {successMessage && (
                  <div className="text-green-200 text-sm">{successMessage}</div>
                )}

                <div className="mt-8">
                  <div className="text-white opacity-70 text-sm">
                    Please enter the code send to your phone number
                  </div>
                  <input
                    type="number"
                    className=" my-3 appearance-none bg-purple-200 relative block w-full p-3  placeholder-gray-500 text-white rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter the code"
                    onChange={(e) => {
                      setOtp(e.target.value);
                    }}
                    value={otp}
                  />
                  <button
                    className={`w-full mt-3 flex justify-center items-center h-12 py-3 bg-gradient-to-r from-purple-600 to-indigo-600 text-white font-semibold rounded-full shadow-lg hover:from-purple-700 hover:to-indigo-700 transition
                    ${
                      !mfaMethod
                        ? "opacity-70 cursor-not-allowed"
                        : "cursor-pointer"
                    }`}
                    onClick={verifyPhoneOtpHandler}
                  >
                    {loading ? <Loader /> : "Verify"}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </CustomModal>

      {showChatModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-gray-800 p-6 rounded-lg w-full max-w-sm text-center shadow-lg mx-4 sm:mx-0">
            <h2 className="text-xl font-semibold text-white mb-4">
              Confirm Delete
            </h2>
            <p className="text-gray-300 mb-6">
              Are you sure you want to delete all chat history
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={deleteAllChatHandler}
                className="bg-red-600 hover:bg-red-700 text-white rounded-md font-semibold px-4 py-2"
              >
                Yes, Delete
              </button>
              <button
                onClick={() => setShowChatModal(false)}
                className="bg-gray-600 hover:bg-gray-700 text-white rounded-md font-semibold px-4 py-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {showDisableMfaModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-gray-800 p-6 rounded-lg w-full max-w-sm text-center shadow-lg mx-4 sm:mx-0">
            <h2 className="text-xl font-semibold text-white mb-4">
              Disable MFA
            </h2>
            <p className="text-gray-300 mb-6">
              Are you sure you want to disable MFA
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={disableMfaHandler}
                className="bg-violet-300  text-white rounded-md font-semibold px-4 py-2"
              >
                Yes, Disable
              </button>
              <button
                onClick={() => setDisableMfaModal(false)}
                className="bg-gray-600 hover:bg-gray-700 text-white rounded-md font-semibold px-4 py-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserSettings;
