import React, { useEffect, useState } from "react";
import backgroundImage from "../../assets/background.png";
import { getAPIBaseUrl } from "../../utility/constants";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";

const EnterpriseDashboard = () => {
  const [enterpriseDetails, setEnterpriseDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [enterpriseUsers, setEnterpriseUsers] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);

  const { currentUser } = useSelector((state) => state.auth);
  const isAdmin =
    currentUser &&
    Object.keys(currentUser).length > 0 && 
    currentUser.user && 
    currentUser.user.role === "admin"
      ? true
      : false;

      console.log("currentUser",currentUser)

  const deleteUserHandler = async () => {
    const data = {
      enterprise_id: currentUser?.enterprise?.public_enterprise_id,
      user_email: selectedUser?.email,
    };
    try {
      const response = await axios.post(
        `${getAPIBaseUrl()}/delete-enterprise-user`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("User deleted successfully");
      setShowModal(false);
      getEnterpriseUsers();
    } catch (error) {
      toast.error(error?.response?.data?.error, { autoClose: 4000 });
    }
  };

  const addUserHandler = async () => {
    setLoading(true);
    const data = {
      enterprise_id: currentUser?.enterprise?.enterprise_id,
      user_name: name,
      user_email: email,
    };
    try {
      const response = await axios.post(
        `${getAPIBaseUrl()}/add-enterprise-user`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success(
        "User added successfully. They will receive an email with login instructions."
      );
      setEmail("");
      setName("");
      getEnterpriseUsers();
    } catch (error) {
      toast.error(error?.response?.data?.error, { autoClose: 4000 });
    } finally {
      setLoading(false);
    }
  };

  const getEnterpriseDetails = async () => {
    const response = await axios.get(
      `${getAPIBaseUrl()}/enterprise-details?enterprise_id=${
        currentUser.enterprise.enterprise_id
      }`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response && response.data && response.data.enterprise) {
      setEnterpriseDetails(response.data.enterprise.Item);
    } else {
      setEnterpriseDetails({});
    }
  };

  const getEnterpriseUsers = async () => {
    const response = await axios.post(
      `${getAPIBaseUrl()}/get-enterprise-users`,
      {
        publicEnterpriseId: currentUser.enterprise.public_enterprise_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (
      response &&
      response.data &&
      response.data.enterprise_users?.length > 0
    ) {
      setEnterpriseUsers(response.data.enterprise_users);
    } else {
      setEnterpriseUsers([]);
    }
  };

  useEffect(() => {
    getEnterpriseDetails();
    getEnterpriseUsers();
  }, []);

  return (
    <div
      className="min-h-screen text-white p-4 sm:p-8"
      style={{
        background: `url(${backgroundImage}) no-repeat center center fixed`,
        backgroundSize: "cover",
      }}
    >
      <h1 className="text-4xl font-extrabold mb-8 text-center text-white">
        Enterprise User Management
      </h1>

      <div className={`grid grid-cols-1  gap-4 lg:gap-8 max-w-5xl mx-auto ${isAdmin ? "lg:grid-cols-2" : "lg:grid-cols-1"}`}>
        <div className="bg-gray-800 rounded-lg p-6 shadow-lg">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-sm">
            <p>
              <span className="font-semibold">Name:</span>{" "}
              {enterpriseDetails?.enterprise_name}
            </p>
            <p>
              <span className="font-semibold">Industry:</span>{" "}
              {enterpriseDetails?.industry}
            </p>
            <p>
              <span className="font-semibold">Address:</span>{" "}
              {enterpriseDetails?.company_address}
            </p>

            <p>
              <span className="font-semibold">State:</span>{" "}
              {enterpriseDetails?.state}
            </p>
            <p>
              <span className="font-semibold">Company Size:</span>{" "}
              {enterpriseDetails?.company_size}
            </p>
            <p>
              <span className="font-semibold">Zip Code:</span>{" "}
              {enterpriseDetails?.zip_code}
            </p>
            <p>
              <span className="font-semibold">Created At:</span>{" "}
              {enterpriseDetails.created_at
                ? moment(enterpriseDetails.created_at).format("MMM DD YYYY")
                : "NA"}
            </p>
          </div>
        </div>

        {isAdmin && (
          <>
            <div className="bg-gray-800 rounded-lg p-6 shadow-lg">
              <h2 className="text-2xl font-semibold mb-1 text-white">
                Add a New User
              </h2>
              <p className="text-xs font-semibold mb-8 text-gray-400">
                We will send an email to the user for password reset on the
                email provided.
              </p>
              <div className="flex flex-col space-y-4">
                <input
                  type="text"
                  placeholder="Full Name"
                  className="bg-gray-700 block w-full p-3 placeholder-gray-500 text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  type="email"
                  placeholder="Email Address"
                  className="bg-gray-700 block w-full p-3 placeholder-gray-500 text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  onClick={addUserHandler}
                  className="bg-purple-600 hover:bg-purple-700 w-full sm:w-auto text-white rounded-md font-semibold transition transform hover:scale-105 px-4 py-1 h-10"
                >
                  {loading ? <Loader /> : "Add User"}
                </button>
              </div>
            </div>

            <div className="bg-gray-800 rounded-lg p-6 shadow-lg lg:col-span-2">
              <h2 className="text-2xl font-semibold mb-4 text-white">
                Current Users
              </h2>
              <div className="overflow-x-auto">
                {!enterpriseUsers.length ? (
                  <p className="text-gray-400">No users added yet.</p>
                ) : (
                  <table className="min-w-full divide-y divide-gray-700">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                          Name
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                          Email
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                          Role
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                          Tokens Used
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                          Last Login
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-gray-800 divide-y divide-gray-700">
                      {enterpriseUsers.map((user) => (
                        <tr key={user.id} className=" transition">
                          <td className="px-6 py-4 text-sm font-medium text-white">
                            {user.user_name}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-400">
                            {user.email}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-400">
                            <span className="bg-violet-200 font-bold rounded-md px-2 py-1.5">
                              {user.role}
                            </span>
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-400 text-center">
                              {user.tokens_used}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-400">
                            {user.last_login
                              ? moment(user.last_login).format(
                                  "MMM DD YYYY HH:mm"
                                )
                              : ""}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium">
                            {user?.role !== "admin" && (
                              <button
                                onClick={() => {
                                  setSelectedUser(user);
                                  setShowModal(true);
                                }}
                                className="text-red-200 hover:text-red-600 font-semibold"
                              >
                                Delete
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-gray-800 p-6 rounded-lg w-full max-w-sm text-center shadow-lg mx-4 sm:mx-0">
            <h2 className="text-xl font-semibold text-white mb-4">
              Confirm Delete
            </h2>
            <p className="text-gray-300 mb-6">
              Are you sure you want to delete {selectedUser?.user_name}?
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={deleteUserHandler}
                className="bg-red-600 hover:bg-red-700 text-white rounded-md font-semibold px-4 py-2"
              >
                Yes, Delete
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="bg-gray-600 hover:bg-gray-700 text-white rounded-md font-semibold px-4 py-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EnterpriseDashboard;
