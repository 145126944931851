import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import ChatScreen from "./pages/ChatScreen";
import { configureStore } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import MSLoginRedirect from "./pages/auth/MSLoginRedirect";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsofService";
import Login from "./pages/auth/Login";
import ResetPassword from "./pages/auth/ResetPassword";
import Signup from "./pages/auth/Signup";
import Billing from "./pages/Payment/Billing";
import MyPlan from "./pages/Payment/MyPlan";
import SuccessPage from "./pages/Payment/SuccessPage";
import UserSettings from "./pages/UserSettings"
import EnterpriseContact from "./pages/EnterpiseContact";
import EnterpriseDashboard from "./pages/EnterpriseDashboard"
import VerifyMFA from "./pages/VerifyMFA"

const googleClientId =
  "454713927448-fpd2ptmgdkl2uhsdfduats3lc78gofkb.apps.googleusercontent.com";

const AppRoutes = () => {
  const routes = useRoutes([
    { path: "/login", element: <Login /> },
    { path: "/create-account", element: <Signup /> },
    {
      path: "/billing",
      element: <Billing />,
    },
    { path: "/", element: <ChatScreen /> },
    { path: "/chat/:chatId", element: <ChatScreen /> },
    { path: "/auth-callback", element: <MSLoginRedirect /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "/terms-of-service", element: <TermsOfService /> },
    { path: "/success", element: <SuccessPage /> },
    { path: "/settings", element: <UserSettings /> },
    { path: "/contact-us", element: <EnterpriseContact /> },
    { path: "/reset-password", element: <ResetPassword /> },
    { path: "/enterprise/:enterpriseId", element: <EnterpriseDashboard /> },
    { path: "/verify-mfa", element: <VerifyMFA /> },
  ]);
  return routes;
};

const { store, persistor } = configureStore();

function App() {
  return (
    <div className="h-screen">
      <GoogleOAuthProvider clientId={googleClientId}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <AppRoutes />
          </PersistGate>
        </Provider>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
